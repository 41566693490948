<template lang="pug">
  .filters
    .row
      .filter.col
        p.filter-title {{ $t("company_system.booking_confirmation.filters.rental_store") }}
        AppDropdown(
          checkbox
          batch-select
          multiple
          close-on-select
          allow-empty
          :value="filters.shops"
          :items="shops"
          @select="applyFilter('shops', $event)"
        )
      .filter.col
        p.filter-title {{ $t("company_system.booking_confirmation.filters.channel") }}
        AppDropdown(
          checkbox
          batch-select
          multiple
          close-on-select
          allow-empty
          :value="filters.channel"
          :items="[]"
          @select="applyFilter('channel', $event)"
        )
      .filter.col
        p.filter-title {{ $t("company_system.booking_confirmation.filters.status") }}
        AppDropdown(
          checkbox
          batch-select
          multiple
          close-on-select
          allow-empty
          orderDirection="keep"
          :value="filters.status"
          :items="statuses"
          @select="applyFilter('status', $event)"
        )
      .filter.col.search
        AppSearch(
          :value="filters.searchValue"
          @change="applyFilter('searchValue', $event)"
        )
    .row
      .filter.col
        p.filter-title {{ $t("company_system.booking_confirmation.filters.application_date") }}
        AppDatepicker(
          :value="filters.reservedAt"
          @change="applyFilter('reservedAt', $event)"
        )
      .filter.col
        p.filter-title {{ $t("company_system.booking_confirmation.filters.loan_date") }}
        AppDatepicker(
          :value="filters.pickUpAt"
          @change="applyFilter('pickUpAt', $event)"
        )
      .filter.col
        p.filter-title {{ $t("company_system.booking_confirmation.filters.return_date") }}
        AppDatepicker(
          :value="filters.dropOffAt"
          @change="applyFilter('dropOffAt', $event)"
        )
      .filter.col
        p.filter-title {{ $t("company_system.booking_confirmation.filters.update_date") }}
        AppDatepicker(
          :value="filters.updatedAt"
          @change="applyFilter('updatedAt', $event)"
        )
</template>

<script>
  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    props: {
      filters: {
        type: Object,
        default: () => new Object()
      },
      shops: {
        type: Array,
        default: () => []
      },
      statuses: {
        type: Array,
        default: () => []
      }
    },

    methods: {
      applyFilter(itemType, items) {
        const filters = { ...this.filters, [itemType]: items }
        this.$emit("change-filters", filters)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .filters
    .filter
      +filter-title

      max-width: 21%
      padding-top: 20px

      ::v-deep
        .app-select
          padding: 6px 0

        .datepicker
          padding-right: 0
          width: 100%

    .search
      margin-top: 32px
      width: 100%
      ::v-deep
        .app-search
          width: 100%
</style>
